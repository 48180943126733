import {AbilityScores} from './AbilityScores';
import { Equipment } from './Equipment';

export class Character {
    Name: string;
    Level: number;
    AbilityScores: AbilityScores;
    Equipment: Equipment;
    TotalHitPoints: number;
    CurrentHitPoints: number;
    InitiativeRoll: number;
}