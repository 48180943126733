import { Component, OnInit } from '@angular/core';
import { Character } from '../shared/models/Character';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { faChevronUp, faChevronDown, faSkullCrossbones } from '@fortawesome/free-solid-svg-icons'

@Component({
  selector: 'app-encounter-tool',
  templateUrl: './encounter-tool.component.html',
  styleUrls: ['./encounter-tool.component.scss']
})

export class EncounterToolComponent implements OnInit {

  characters: Array<Character> = new Array<Character>();
  additionalChar: Character = new Character();
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;
  faSkullCrossbones = faSkullCrossbones;
  currentTurn: string;

  constructor() { }

  ngOnInit() {
  }

  incrementHitPoints(c: Character) {
    if (c.CurrentHitPoints < c.TotalHitPoints) {
      c.CurrentHitPoints++;
    }
  }
  
  decrementHitPoints(c: Character) {
    c.CurrentHitPoints--;
  }

  add() {
    this.additionalChar.CurrentHitPoints = this.additionalChar.TotalHitPoints;
    this.characters.push(this.additionalChar);
    this.additionalChar = new Character();
    this.characters.sort((a, b) => {
      if (a.InitiativeRoll < b.InitiativeRoll) return 1;
      else if (a.InitiativeRoll > b.InitiativeRoll) return -1;
      else return 0;
    })
  }
  
  drop(event: CdkDragDrop<string[]>) {
    this.moveItemInArray(this.characters, event.previousIndex, event.currentIndex);
  }
  
  moveItemInArray(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  };

  begin() {
    this.currentTurn = this.characters[0].Name;

  }

  next() {
    var index, firstIndex, nextIndex;
    this.characters.forEach((c,i) => {
      if (!firstIndex && firstIndex != 0 && c.CurrentHitPoints > 0) {
        firstIndex = i;
      }
      if ((index || index == 0) && !nextIndex && c.CurrentHitPoints > 0) {
        nextIndex = i;
      }
      if (c.Name == this.currentTurn) {
        index = i;
      }
    });
    this.currentTurn = nextIndex ? this.characters[nextIndex].Name : this.characters[firstIndex].Name;
  }

  kill (c: Character) {
    c.CurrentHitPoints = 0;
  }

}